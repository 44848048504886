var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4",attrs:{"fluid":""}},[_c('v-overlay',{attrs:{"value":_vm.isLoaderActive,"color":"primary"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"50","color":"primary"}})],1),_c('v-card',{staticClass:"mb-10"},[_c('v-row',{staticClass:"ml-4 mr-4 mt-1 mb-4"},[_c('v-toolbar-title',{attrs:{"dark":"","color":"primary"}},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5"},[_c('strong',[_vm._v(_vm._s(_vm.entity))])]),_c('v-list-item-subtitle',[_vm._v("Home "),_c('v-icon',[_vm._v("mdi-chevron-right")]),_vm._v(" Users "),_c('v-icon',[_vm._v("mdi-chevron-right")]),_vm._v(" "+_vm._s(_vm.entity))],1)],1)],1)],1),_c('v-spacer'),_c('v-btn',{directives:[{name:"permission",rawName:"v-permission",value:('Add User'),expression:"'Add User'"}],staticClass:"white--text primary-button mx-0 d-none d-md-block mr-4 mt-4",attrs:{"disabled":_vm.tableDataLoading},on:{"click":function($event){_vm.isAddEdit = true;
          _vm.showAddEditPage(null);}}},[_vm._v(" Add "+_vm._s(_vm.entity)+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-plus ")])],1)],1),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.tableHeader,"items":_vm.dataTableRowNumbering,"item-key":"id","options":_vm.pagination,"fixed-header":"","height":"450","loading":_vm.tableDataLoading,"loading-text":_vm.tableLoadingDataText,"server-items-length":_vm.totalItemsInDB,"items-per-page":7,"footer-props":{
          itemsPerPageOptions: [7, 15, 25, 50],
        }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',{staticClass:"font-weight-black bold title",staticStyle:{"color":"red"}},[_vm._v(" No Data Found ")])]},proxy:true},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"Search","prepend-inner-icon":"mdi-magnify"},on:{"input":_vm.searchInfo},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('v-spacer'),_c('span',{staticClass:"text-right mx-1 d-none d-md-block"},[_c('strong',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.totalItemsInDB)+" ")]),_vm._v(" Records Found ")]),(!_vm.tableDataLoading)?_c('v-btn',{staticClass:"mx-md-4 mx-sm-1",attrs:{"icon":"","small":"","color":"success","size":"24"}},[_c('download-excel',{attrs:{"data":_vm.tableItems,"fields":_vm.excelFields,"name":_vm.excelFileName}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-cloud-download")])],1)],1):_vm._e(),_c('v-btn',{directives:[{name:"permission",rawName:"v-permission",value:('Add User'),expression:"'Add User'"}],staticClass:"white--text my-auto mr-1 ml-3 d-md-none",attrs:{"icon":"","small":"","size":"24","disabled":_vm.tableDataLoading,"color":"primary"},on:{"click":function($event){_vm.isAddEdit = true;
                _vm.showAddEditPage(null);}}},[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)],1)]},proxy:true},{key:"item.user_status",fn:function(ref){
                var item = ref.item;
return [_c('v-switch',{directives:[{name:"permission",rawName:"v-permission",value:(
                'ChangeStatusofAgent' |
                'ChangeStatusofAdmin' |
                'ChangeStatusofSuperuser' |
                'ChangeStatusofOperator' |
                'ChangeStatusofIndividual'
            ),expression:"\n                'ChangeStatusofAgent' |\n                'ChangeStatusofAdmin' |\n                'ChangeStatusofSuperuser' |\n                'ChangeStatusofOperator' |\n                'ChangeStatusofIndividual'\n            "}],attrs:{"color":item.user_status == 'Active' ? 'green' : 'red',"inset":"","dense":"","false-value":"Inactive","true-value":"Active"},on:{"change":function($event){return _vm.enableDisableItem(item)}},model:{value:(item.user_status),callback:function ($$v) {_vm.$set(item, "user_status", $$v)},expression:"item.user_status"}})]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"x-small":"","color":_vm.setStatusColor(item.status),"dark":""}},[_vm._v(_vm._s(item.status))])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{directives:[{name:"permission",rawName:"v-permission",value:('Edit User'),expression:"'Edit User'"}],staticClass:"mx-1 fitPotPrimaryIcon",attrs:{"size":"22"},on:{"click":function($event){_vm.isAddEdit = false;
              _vm.showAddEditPage(item);}}},[_vm._v("mdi-square-edit-outline")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }