<template>
  <v-main>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </v-main>
</template>

<script>
import styles from "./app.css";
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
